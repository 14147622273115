<template>
    <div>
        <DxToolbar :height="56" class="toolbar">
            <DxItem location="before" locate-in-menu="never">
                <template #default>
                    <router :breadcrumbsItems="breadcrumbsItems" />
                </template>
            </DxItem>
            <DxItem location="after">
                <template #default>
                    <search-vue
                        @search="searchs"
                        @model="modelSearch"
                        id="textbox_BCHoatDongXe_Timkiem"
                    />
                </template>
            </DxItem>
        </DxToolbar>
        <div class="pl-3">
            <DxValidationGroup ref="formValidation">
                <div
                    class="row align-center pt-2 pb-4"
                    style="border-bottom: 1px solid #ddd"
                >
                    <span class="mr-2 font-16" id="label_BCHoatDongXe_Tu"
                        >Từ</span
                    >
                    <DxDateBox
                        v-model="model.DateFrom"
                        displayFormat="dd/MM/yyyy"
                        dropDownButtonTemplate="customIcon"
                        validationMessageMode="always"
                        id="datebox_BCHoatDongXe_TuNgay"
                        class="datebox-baocao"
                        stylingMode="underlined"
                        :useMaskBehavior="true"
                    >
                        <DxValidator>
                            <DxRequiredRule message="Không được để trống!" />
                            <DxRangeRule
                                :max="maxDate"
                                :message="$i18n.t('DefaultString.MaxDate')"
                            />
                            <DxRangeRule
                                :min="minDate"
                                :message="$i18n.t('DefaultString.MinDate')"
                            />
                            <DxRangeRule
                                :max="model.DateTo"
                                message="Từ ngày phải nhỏ hơn đến ngày"
                            />
                        </DxValidator>
                        <template #customIcon="{}">
                            <DxButton icon="mdi mdi-calendar" />
                        </template>
                    </DxDateBox>
                    <span
                        style="
                            border: 1px solid #707070;
                            height: 2px;
                            width: 10px;
                        "
                        class="mx-2"
                    ></span>
                    <DxDateBox
                        v-model="model.DateTo"
                        displayFormat="dd/MM/yyyy"
                        dropDownButtonTemplate="customIcon"
                        validationMessageMode="always"
                        id="datebox_BCHoatDongXe_DenNgay"
                        class="datebox-baocao"
                        stylingMode="underlined"
                        :useMaskBehavior="true"
                    >
                        <DxValidator>
                            <DxRequiredRule message="Không được để trống!" />
                            <DxRangeRule
                                :max="maxDate"
                                :message="$i18n.t('DefaultString.MaxDate')"
                            />
                            <DxRangeRule
                                :min="minDate"
                                :message="$i18n.t('DefaultString.MinDate')"
                            />
                            <DxRangeRule
                                :min="model.DateFrom"
                                message="Từ ngày phải lớn hơn đến ngày"
                            />
                        </DxValidator>
                        <template #customIcon="{}">
                            <DxButton icon="mdi mdi-calendar" />
                        </template>
                    </DxDateBox>
                    <DxButton
                        class="ml-3"
                        :icon="$i18n.t('icon.search')"
                        text="Lọc"
                        styling-mode="outlined"
                        :rtlEnabled="true"
                        id="button_BCHoatDongXe_Loc"
                        @click="validate"
                    />
                </div>
            </DxValidationGroup>
            <ToolbarVue
                :Params="data_from_api"
                @selectionChange="selectionChange"
                @reload="reloadFuc"
            />
            <DatatableVue
                ref="data_from_api"
                :Params="data_from_api"
                :Reload="reload"
            />
        </div>
    </div>
</template>
<script>
import router from "@/components/_Common/Components/router";
import ToolbarVue from "@/components/BaoCaoThongKe/BaoCaoHoatDongCuaXe/Toolbar";
import searchVue from "@/components/_Common/Components/Search";
import DatatableVue from "@/components/BaoCaoThongKe/BaoCaoHoatDongCuaXe/Datatable";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxDateBox, DxButton, DxValidator } from "devextreme-vue";
import { DxRangeRule, DxRequiredRule } from "devextreme-vue/data-grid";
import { DxValidationGroup } from "devextreme-vue/validation-group";

export default {
    layout: "application",
    components: {
        router,
        ToolbarVue,
        DatatableVue,
        searchVue,
        DxToolbar,
        DxValidationGroup,
        DxDateBox,
        DxValidator,
        DxRequiredRule,
        DxRangeRule,
        DxValidator,
        DxButton,
        DxItem,
    },
    data() {
        return {
            url: this.$i18n.t("url.BaoCaoHoatDongXeList"),
            url_capdo: this.$i18n.t("url.CapDoTaiKhoan"),
            maxDate: new Date(this.$i18n.t("DefaultNumBer.MaxDate")),
            minDate: new Date(this.$i18n.t("DefaultNumBer.MinDate")),
            id_table: "IdBaoCao",
            model: {
                DateFrom: new Date(),
                DateTo: new Date(),
                Search: "",
                IdSos: 0,
                IdBens: 0,
                IdDvvts: 0,
            },
            btn: [
                {
                    name: "password",
                    location: "after",
                    options: {
                        icon: "mdi mdi-calendar",
                        type: "normal",
                        onClick: () => {},
                    },
                },
            ],
            data_from_api: {
                Type: null,
                DataSelect: {
                    SelectSo: [],
                    SelectBenXe: [],
                    SelectDVVT: [],
                },
                DataTable: [],
            },
            reload: 0,
            breadcrumbsItems: [
                {
                    id: "breadcrums_BCHoatDongXe_BaoCaoThongKe",
                    text: this.$i18n.t("stringRouter.nodeBCTK"),
                    disabled: true,
                    to: "",
                },
                {
                    id: "breadcrums_BCHoatDongXe_BaoCaoHoatDongXe",
                    text: this.$i18n.t("stringRouter.brancheBCHD"),
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    methods: {
        async getData() {
            try {
                let query = {
                    tuNgay: this.$Helper.ConvertToTimeTicks(
                        this.model.DateFrom
                    ),
                    denNgay: this.$Helper.ConvertToTimeTicks(this.model.DateTo),
                    idSos: this.model.IdSos,
                    idBens: this.model.IdBens,
                    idDonVis: this.model.IdDvvts,
                    txtTimKiem: this.model.Search || "",
                };

                this.data_from_api.DataTable = (
                    await this.$store.dispatch(
                        "BaoCaoThongKe/LayDanhSachBaoCaoHoatDongXe",
                        query
                    )
                ).Store;
            } catch (error) {
                console.log(error);
            }
        },
        reloadFuc() {
            this.startValue = new Date();
            this.endValue = new Date();
            this.model = {
                DateFrom: new Date(),
                DateTo: new Date(),
                Search: "",
                IdSos: 0,
                IdBens: 0,
                IdDvvts: 0,
            };
            this.getData();
        },
        searchs() {
            this.getData();
        },
        modelSearch(search) {
            this.model.Search = search;
        },
        selectionChange(data) {
            this.model.IdBens = data.SelectBenXe;
            this.model.IdDvvts = data.SelectDVVT;
            this.model.IdSos = data.SelectSo;
        },
        validateDate(e) {
            return (
                e.value >= new Date(this.$i18n.t("DefaultNumBer.MinDate")) &&
                e.value <= new Date(this.$i18n.t("DefaultNumBer.MaxDate"))
            );
        },
        validate(e) {
            let result = this.$refs.formValidation.instance.validate();
            if (!!result.isValid) {
                this.getData();
            }
        },
        setDataFromQuery() {
            if (this.$route.query.DateFrom) {
                this.model.DateFrom = new Date(this.$route.query.DateFrom);
                this.model.DateTo = new Date(this.$route.query.DateTo);
                this.model.IdSos = this.$route.query.IdSo;
                this.model.IdBens = this.$route.query.IdBenXe;
            }
        },
    },
    watch: {},
    created() {
        this.data_from_api.Type =
            this.$Core.Auth.TokenParsed?.CauHinhCu?.LoaiDonVi;
    },
    mounted() {
        this.setDataFromQuery();

        // this.getData();
    },
};
</script>
<style>
.datebox-baocao .dx-dropdowneditor-button {
    width: 100%;
}
.datebox-baocao
    .dx-datebox.dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-underlined
    .dx-texteditor-input,
.dx-datebox:not(.dx-texteditor-empty).dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-underlined
    .dx-texteditor-input {
    padding-right: unset;
    max-width: 150px;
}
</style>
<style scoped>
>>> #btn-filter.dx-button .dx-button-content {
    flex-direction: row-reverse;
    display: flex;
}
>>> #btn-filter.dx-button.dx-button-has-icon.dx-button-has-text .dx-icon {
    margin-left: 8px;
    margin-right: 0px;
    line-height: 22px;
    font-size: 21px;
}
</style>