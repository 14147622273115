<template>
    <div>
        <MainDatatableVue
            ref="Params"
            :Params="Params"
            v-if="Params.Type == $i18n.t('string.tongcuc')"
        >
            <template v-slot:dx-column>
                <DxColumn
                    :allowFiltering="false"
                    alignment="left"
                    data-field="TenSo"
                    caption="Sở"
                    :group-index="0"
                ></DxColumn>
                <DxColumn
                    :allowFiltering="false"
                    alignment="left"
                    data-field="TenBenXe"
                    caption="Bến"
                    :group-index="1"
                ></DxColumn>
            </template>
        </MainDatatableVue>
        <MainDatatableVue
            :Params="Params"
            v-else-if="Params.Type == $i18n.t('string.so')"
        >
            <template v-slot:dx-column>
                <DxColumn
                    alignment="left"
                    data-field="TenBenXe"
                    caption="Bến"
                    :group-index="0"
                ></DxColumn>
                <DxColumn
                    alignment="left"
                    data-field="TenDoanhNghiep"
                    caption="Đơn vị vận tải"
                    :group-index="1"
                    :allowFiltering="false"
                ></DxColumn>
            </template>
        </MainDatatableVue>
        <MainDatatableVue
            :Params="Params"
            v-else-if="Params.Type == $i18n.t('string.benxe')"
        >
            <template v-slot:dx-column>
                <DxColumn
                    alignment="left"
                    data-field="TenDoanhNghiep"
                    caption="Đơn vị vận tải"
                    :group-index="0"
                    :groupCellTemplate="groupCellTemplate"
                    :allowFiltering="false"
                ></DxColumn>
            </template>
        </MainDatatableVue>
    </div>
</template>
<script>
import MainDatatableVue from "./MainDatatable.vue";
import { DxColumn } from "devextreme-vue/data-grid";
export default {
    props: {
        Params: { type: Object, default: {} },
    },
    components: {
        MainDatatableVue,
        DxColumn,
    },
    methods: {
        groupCellTemplate(e, col) {
            e.innerText = col.value;
        },
    },
};
</script>