<template>
    <DxDataGrid
        class="pt-2 table-page"
        id="table_BCHoatDongXe_DanhSachBaoCao"
        :show-borders="true"
        :data-source="Params.DataTable"
        :remote-operations="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        @cellHoverChanged="onCellHoverChanged"
        ref="table_BCHoatDongXe_DanhSachBaoCao"
        key-expr="IdBaoCao"
        height="calc(100vh - 170px)"
        :loadPanel="{ showIndicator: false, showPane: false, text: '' }"
    >
        <DxPaging :page-size="parseInt($i18n.t('Settings.PageSize'))" />
        <!-- <DxGrouping :auto-expand-all="false"/> -->
        <DxScrolling
            :mode="$i18n.t('Settings.DxScrolling_Mode')"
            :row-rendering-mode="
                $i18n.t('Settings.DxScrolling_RowRenderingMode')
            "
        />
        <DxColumnFixing :enabled="true" />
        <DxHeaderFilter :visible="true" />
        <slot name="dx-column"></slot>
        <!-- <DxColumn
            :allowFiltering="false"
            :allowSorting="false"
            :fixed="true"
            alignment="left"
            data-field="STT"
            caption="STT"
            width="56"
        ></DxColumn> -->
        <DxColumn
            :allowFiltering="false"
            :fixed="true"
            alignment="left"
            data-field="BienKiemSoat"
            caption="Biển kiểm soát"
        ></DxColumn>
        <DxColumn
            :allowFiltering="false"
            alignment="left"
            data-field="SoGhe"
            caption="Số ghế"
        ></DxColumn>
        <DxColumn
            :allowFiltering="false"
            alignment="left"
            data-field="SoGiuong"
            caption="Số giường"
        ></DxColumn>
        <DxColumn
            :allowFiltering="false"
            alignment="left"
            data-field="TenDoanhNghiep"
            caption="Đơn vị vận tải"
        ></DxColumn>
        <DxColumn
            alignment="left"
            data-field="HinhThucChay"
            caption="Hoạt động vận chuyển"
            css-class="datagrid-header-white-space"
            width="152"
        ></DxColumn>
        <DxColumn
            alignment="left"
            data-field="TrangThaiDuDieuKien"
            data-type="string"
            caption="Điều kiện xuất bến"
        ></DxColumn>
        <DxColumn
            :allowFiltering="false"
            alignment="left"
            data-field="TenTuyen"
            caption="Tuyến vận chuyển"
        ></DxColumn>
        <DxColumn
            caption="Thông tin giấy tờ"
            :allowFiltering="false"
            alignment="center"
        >
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="HanDangKiem"
                caption="Hạn đăng kiểm"
                data-type="date"
                format="dd-MM-yyyy"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="HanBaoHiem"
                caption="Hạn bảo hiểm"
                data-type="date"
                format="dd-MM-yyyy"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="HanPhuHieu"
                caption="Hạn phù hiệu"
                data-type="date"
                format="dd-MM-yyyy"
            ></DxColumn>
        </DxColumn>
        <!-- <DxColumn caption="Nhân viên" :allowFiltering="false" alignment="center">
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="TenLaiXe"
                caption="Lái xe 1"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="TenPhuXe"
                caption="Lái xe 2"
            ></DxColumn>
        </DxColumn> -->
        <DxColumn caption="Bến đi" :allowFiltering="false" alignment="center">
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="BenDiGioDenBen"
                caption="Giờ vào bến"
                data-type="date"
                format="HH:mm dd-MM-yyyy"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="BenDiGioCapLenNot"
                caption="Giờ cấp lên nốt"
                data-type="date"
                format="HH:mm dd-MM-yyyy"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="BenDiGioCapLenhXuatBen"
                caption="Giờ cấp lệnh xuất bến"
                css-class="datagrid-header-white-space"
                width="145"
                data-type="date"
                format="HH:mm dd-MM-yyyy"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="BenDiGioXuatBenKeHoach"
                caption="Giờ XB kế hoạch"
                data-type="date"
                format="HH:mm dd-MM-yyyy"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="BenDiGioXuatBenThucTe"
                caption="Giờ XB thực tế"
                data-type="date"
                format="HH:mm dd-MM-yyyy"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="BenDiSoKhach"
                caption="Số khách"
            ></DxColumn>
        </DxColumn>
        <DxColumn caption="Bến đến" :allowFiltering="false" alignment="center">
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="BenDenTenBen"
                caption="Tên bến đến"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="BenDenGioDenBen"
                caption="Giờ đến bến"
                data-type="date"
                format="HH:mm dd-MM-yyyy"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="BenDenGioXacNhanTraKhach"
                caption="Giờ xác nhận trả khách"
                data-type="date"
                css-class="datagrid-header-white-space"
                width="145"
                format="HH:mm dd-MM-yyyy"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="BenDenSoKhach"
                caption="Số khách"
            ></DxColumn>
        </DxColumn>
        <DxColumn
            caption="Giám sát hành trình"
            :allowFiltering="false"
            alignment="center"
        >
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="GshtTocDoTrungBinh"
                caption="Tốc độ trung bình (Km/h)"
                css-class="datagrid-header-white-space"
                width="120"
            ></DxColumn>
            <DxColumn
                :allowFiltering="false"
                alignment="left"
                data-field="GshtTocDoCaoNhat"
                caption="Tốc độ cao nhất (Km/h)"
                css-class="datagrid-header-white-space"
                width="120"
            ></DxColumn>
        </DxColumn>
        <DxColumn
            :allowFiltering="false"
            alignment="left"
            data-field="GhiChu"
            caption="Ghi chú"
        ></DxColumn>
        <DxSummary>
            <DxGroupItem
                column="SoGhe"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="SoGiuong"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="BenDiSoKhach"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="BenDenSoKhach"
                summary-type="sum"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummary"
            />
            <DxGroupItem
                column="BenDiGioXuatBenThucTe"
                showInColumn="TenBenXe"
                summary-type="count"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummaryGroup"
                v-if="Params.Type == $i18n.t('string.tongcuc')"
            />
            <DxGroupItem
                column="BenDiGioXuatBenThucTe"
                showInColumn="TenDoanhNghiep"
                summary-type="count"
                :show-in-group-footer="false"
                :align-by-column="true"
                :customize-text="customizeSummaryGroup"
                v-else-if="Params.Type == $i18n.t('string.so')"
            />
        </DxSummary>
        <!-- Hover button -->
        <DxColumn
            cssClass="column-button-hover"
            data-field="Detail"
            caption="Trạng thái"
            width="1"
            cell-template="buttons-cell"
        ></DxColumn>

        <template #buttons-cell="{ data }">
            <div class="buttons">
                <DxButton
                    id
                    icon="mdi mdi-eye"
                    @click="XemChiTiet(data)"
                    v-if="
                        $Helper.Common.CheckRole(
                            $i18n.t('Quyen.BaoCaoBenXe'),
                            $i18n.t('Quyen.ChiTietBaoCaoHoatDongCuaXe')
                        )
                    "
                ></DxButton>
            </div>
        </template>
    </DxDataGrid>
</template>
<script>
import { DxDataGrid, DxButton } from "devextreme-vue";
import {
    DxColumn,
    DxPaging,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxSummary,
    DxGroupItem,
} from "devextreme-vue/data-grid";
export default {
    components: {
        DxDataGrid,
        DxPaging,
        DxScrolling,
        DxColumnFixing,
        DxHeaderFilter,
        DxColumn,
        DxSummary,
        DxGroupItem,
        DxButton,
    },
    props: {
        Params: { type: Object, default: {} },
    },
    data() {
        return {};
    },
    methods: {
        customizeSummary(data) {
            return data.value;
        },
        customizeSummaryGroup(data) {
            return "Lượt XB: " + data.value;
        },
        XemChiTiet(data) {
            localStorage.setItem("BienKiemSoat", data.data.IdXe);
            localStorage.setItem("NgayHoatDong", data.data.NgayTao);
            window.open(
                "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe/Chi-Tiet",
                "_blank"
            );
        },
        onCellHoverChanged(e) {
            this.$Helper.Common.table({ Element: e, Buttons: true }).HoverRow();
        },
    },
    mounted() {
        let self = this;
        this.emitter.on("excel", function () {
            let init = {
                Element: self.$refs.table_BCHoatDongXe_DanhSachBaoCao.instance,
                WorkSheet: "Danh Sách",
                FileName: "Bao-Cao-Hoat-Dong-Cua-Xe",
            };
            self.$Helper.Common.excel(init).Export();
        });
        this.emitter.on("reload", function () {
            let datagrid =
                self.$refs.table_BCHoatDongXe_DanhSachBaoCao.instance;
            datagrid.clearFilter();
        });
    },
    beforeUnmount() {
        this.emitter.off("excel");
    },
};
</script>
<style scoped>
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="5"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="6"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="21"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="25"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="26"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="27"] {
    text-align: right !important;
}
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="11"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="12"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="13"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="16"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="17"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="18"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="19"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="23"],
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="24"] {
    text-align: center !important;
}
>>> .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    font-size: 12px !important;
}
</style>